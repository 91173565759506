import Lenis from '@studio-freight/lenis'

// lenis inertia scroll
const lenis = new Lenis()
function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
}
requestAnimationFrame(raf)

export {
    lenis
}