import.meta.glob([
    '../images/**',
    '../fonts/**',
]);

import './bootstrap';
import './app-init';
import { lenis } from './lenis-init';
import './gsap-animations';
import { initComponent } from './components'
import { gsap } from "gsap";

// Alpine store init
document.addEventListener('alpine:init', () => {
    Alpine.store('activeComponent', {
        name: '',
    })
})

// get active full-page component upon load and activate component's three scene
Livewire.on('activate-component', ({ name }) => {
    window.scrollTo(0, 0);
    lenis.scrollTo(0, {
        immediate: true
    });
    Alpine.store('activeComponent').name = name
    initComponent(name)
})


