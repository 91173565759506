import { initHome } from './components/home'
import { initCases } from './components/cases'
import { initAbout } from './components/about'
import { initTeam } from './components/team'
import { initPricing } from './components/pricing'
import { initCareer } from './components/career'
import { initContact } from './components/contact'

// separate three scene router based on active component
function initComponent(componentName) {
    switch (componentName) {
        case 'home':
            initHome()
            break;
        case 'cases':
            initCases()
            break;
        case 'about':
            initAbout()
            break;
        case 'team':
            initTeam()
            break;
        case 'pricing':
            initPricing()
            break;
        case 'career':
            initCareer()
            break;
        case 'contact':
            initContact()
            break;
    }
}

export {
    initComponent
} 