import * as THREE from 'three';
import { gsap } from "gsap";
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';

function initAbout() {

    // Three cache - true for production
    THREE.Cache.enabled = true;

    // scene
    const scene = new THREE.Scene()

    // loaders
    let dLoader = new DRACOLoader()
    let gLoader = new GLTFLoader()

    // camera
    const camera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.set(0, 0, 8);

    // renderer
    const container = document.getElementById(Alpine.store('activeComponent').name);
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(1)
    renderer.capabilities.getMaxAnisotropy()
    renderer.capabilities.getMaxPrecision()
    renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true
    renderer.shadowMap.autoUpdate = true;
    renderer.shadowMap.needsUpdate = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.toneMapping = THREE.ReinhardToneMapping;
    renderer.toneMappingExposure = 0.35;
    container.appendChild(renderer.domElement);

    // environment HDR map
    let standaloneHDR
    new RGBELoader().setPath('images/hdr/').load('hdr3.hdr', function (hdrmap) {
        hdrmap.mapping = THREE.EquirectangularReflectionMapping;
        scene.environment = hdrmap
        standaloneHDR = hdrmap
    })

    // load models
    let rockModel
    gLoader.load(
        'images/3d/rock.glb',
        function (gltf) {

            rockModel = gltf.scene

            let s = 6
            rockModel.scale.set(s, s, s)

            rockModel.traverse((child) => {
                child.castShadow = true;
                child.receiveShadow = true;
            });

            rockModel.name = 'rock'

            scene.add(rockModel)
        },
        function (xhr) { },
        function (error) { }
    );

    // animate function
    let animationId
    function animate() {
        animationId = requestAnimationFrame(animate);

        if (rockModel) {
            let rotationSpeed = 0.00025
            rockModel.rotation.y -= rotationSpeed
            rockModel.rotation.x -= rotationSpeed
            rockModel.rotation.z -= rotationSpeed
        }

        scene.updateMatrixWorld()
        camera.updateProjectionMatrix();
        renderer.render(scene, camera);
    }

    animate();

    // stops scene from propagating if component is being replaced by another
    Livewire.on('activate-component', () => {
        cancelAnimationFrame(animationId)
        camera.position.set(0, 0, 8);
    })

    Livewire.on('init-gsap', () => {
        // homepage intro text animation
        let aboutTextTimeline = gsap.timeline({ repeat: 0 });
    })
}

export {
    initAbout
}
